<template>
    <section ref="testimonials" id="testimonials" :class="[
        'testimonials-section',
        isBackgroundPrimary ? 'bg-gradient-primary text-white' : '',
        'py-4 mb-2 min-vh-100 rounded'
    ]">
        <div class="container text-center d-flex flex-column justify-content-center h-100">
            <h3 :class="[
                isBackgroundPrimary ? 'text-info' : 'text-dark',
            ]">
                TÉMOIGNAGES
            </h3>
            <h1 :class="[
                isBackgroundPrimary ? 'text-light' : 'text-primary',
            ]">
                Clients Satistfaits
            </h1>
            <b-row class="mt-4">
                <b-col md="4" v-for="(testimonial, index) in testimonials" :key="index">
                    <b-card class="bg-light text-dark">
                        <b-card-text>{{ testimonial.text }}</b-card-text>
                        <footer class="blockquote-footer text-secondary">{{ testimonial.author }}</footer>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </section>
</template>

<script>
import { BCard, BImg, BRow, BCol, BCardText } from 'bootstrap-vue'
export default {
    name: 'TestimonialsSection',

    components: {
        BCard,
        BCardText,
        BImg,
        BRow,
        BCol
    },
    props: {
        isBackgroundPrimary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            testimonials: [
                { text: "This app has transformed my practice. Highly recommend it!", author: "Dr. Smith" },
                { text: "An essential tool for any modern dental practice. consectetur adipiscing elit, sed do eiusmod.", author: "Dr. Johnson" },
                { text: "Easy to use and very effective. Great support team! exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat", author: "Dr. Williams" },
                { text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", author: "Dr. Watkins" },
                { text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.", author: "Dr. James" },
                { text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", author: "Dr. Adams" }
            ]
        };
    }
}
</script>

<style scoped>
.min-vh-100 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.testimonials-section .b-card {
    border: none;
    margin-top: 20px;
}

/* Responsive font size */
.testimonials-section h1 {
    font-size: 2.5rem;
    /* Adjust as needed */
}

/* Responsive design for various screen sizes */
@media (max-width: 768px) {
    .testimonials-section h1 {
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
    .testimonials-section h1 {
        font-size: 1.5rem;
    }
}
</style>