<template>
  <section id="faq" ref="faq" :class="[
    'faq-section',
    isBackgroundPrimary ? 'bg-gradient-primary text-white' : '',
    'py-5 min-vh-100 rounded'
  ]">
    <div class="container d-flex flex-column justify-content-center h-100">
      <h1 :class="[
        'text-center',
        isBackgroundPrimary ? 'text-light' : 'text-primary',
      ]">Frequently Asked Questions</h1>
      <app-collapse accordion type="margin" class="mt-4 text-primary">
        <app-collapse-item v-for="(item, index) in faqs" :key="index" :title="item.question" aria-expanded="false">
          {{ item.answer }}
        </app-collapse-item>
      </app-collapse>
    </div>
  </section>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'FaqSection',
  components: {
    AppCollapse,
    AppCollapseItem
  },
  props: {
    isBackgroundPrimary: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      faqs: [
        { question: 'How do I get started?', answer: 'Register an account and follow the setup guide to start using the app.' },
        { question: 'Is there a free trial?', answer: 'Yes, we offer a 14-day free trial with full access to all features.' },
        { question: 'Can I upgrade my plan later?', answer: 'Absolutely! You can upgrade or downgrade your plan anytime from your account settings.' },
        { question: 'Lorem Ipsum Dolor Sit?', answer: 'Absolutely! You can upgrade or downgrade your plan anytime from your account settings.' },
        { question: 'Dolor Sit Amet Ipsum Lorem Akiris?', answer: 'Absolutely! You can upgrade or downgrade your plan anytime from your account settings.' }
      ]
    }
  }
}
</script>


<style scoped>
.min-vh-100 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.faq-section .b-card {
  border: none;
  margin-top: 20px;
}

/* Responsive font size */
.faq-section h1 {
  font-size: 2.5rem;
  /* Adjust as needed */
}

/* Responsive design for various screen sizes */
@media (max-width: 768px) {
  .faq-section h1 {
    font-size: 2rem;
  }
}

@media (max-width: 576px) {
  .faq-section h1 {
    font-size: 1.5rem;
  }
}
</style>