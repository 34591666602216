<template>
    <section ref="hero" id="hero" :class="[
        'hero-section text-center',
        isBackgroundPrimary ? 'bg-gradient-primary text-white' : '',
        'py-5 min-vh-100 rounded'
    ]" :style="{ backgroundImage: `url(${require('@/assets/images/banner/aquaMedico-banner.jpg')})` }">
        <div class="container d-flex flex-column justify-content-center h-100">
            <h1 class="text-white"><b>AquaMedico</b></h1>
            <h2 class="text-white">Pour une organisation <b><i>optimisée</i></b> de votre cabinet.</h2>
            <div class="button-container text-center mt-3">
                <b-button variant="light" @click="$emit('scrollToSection', 'advantages')" pill>
                    <span class="text-primary">Explorer</span>
                </b-button>
                <b-button variant="primary" @click="$emit('scrollToSection', 'testimonials')" pill>
                    <span class="text-light">Retours de nos clients</span>
                </b-button>
            </div>
        </div>
    </section>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
    name: 'HeroSection',
    components: {
        BButton
    },
    props: {
        isBackgroundPrimary: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style scoped>
.min-vh-100 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero-section {
    background-repeat: no-repeat;
    background-size: cover; /* Changed to cover */
    background-position: center; /* Center the image */
}

.hero-section h1 {
    font-size: 6rem;
    margin-bottom: 3rem;
}

@media (max-width: 768px) {
    .hero-section h1 {
        font-size: 5rem;
        margin-bottom: 3rem;
    }
}

@media (max-width: 576px) {
    .hero-section h1 {
        font-size: 3.5rem;
        margin-bottom: 2.5rem;
    }
}

@media (max-width: 375px) {
    .hero-section h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    /* Adjust spacing between buttons as needed */
}
</style>
