<template>
    <div :class="[
        'specialties-section',
        isBackgroundPrimary ? 'bg-gradient-primary' : '',
        'pb-2 min-vh-100'
    ]">
        <!-- Search Input -->
        <section id="product-search">
            <b-card no-body class="product-bg text-center mb-25" :style="{ backgroundImage: `url(${bannerImage})` }">
                <b-card-body class="card-body pb-4">
                    <h1 :class="isBackgroundPrimary ? 'text-white' : 'text-primary'">
                        Nos Spécialités
                    </h1>
                    <b-card-text class="mb-1">
                        <span>Popular searches: </span>
                        <span class="font-weight-bolder">Dentist, Dermatologist</span>
                    </b-card-text>

                    <!-- Form -->
                    <b-form class="product-search-input">
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                                <feather-icon icon="SearchIcon" />
                            </b-input-group-prepend>
                            <b-form-input id="searchbar" v-model="productSearchQuery"
                                placeholder="Search for a product..." />
                        </b-input-group>
                    </b-form>
                </b-card-body>
            </b-card>
        </section>

        <!-- Product Content -->
        <section id="product-content" class="py-2">
            <b-row class="product-content-info match-height px-1">
                <b-col v-for="item in filteredProducts" :key="item.id" md="3" sm="6" class="product-content">
                    <router-link :to="{ name: item.routeName }">
                        <b-card class="text-center cursor-pointer" :img-src="item.img" :img-alt="item.title" img-top>
                            <h4>{{ item.title }}</h4>
                            <b-card-text class="mt-1 text-secondary">
                                {{ item.desc }}
                            </b-card-text>
                        </b-card>
                    </router-link>
                </b-col>
                <b-col v-show="!filteredProducts.length" cols="12" class="text-center">
                    <h4 class="mt-4 text-primary">
                        No products found!
                    </h4>
                </b-col>
            </b-row>
        </section>
    </div>
</template>

<script>
import { BRow, BCol, BCard, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BCardBody, } from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BForm,
        BCardBody,
        BInputGroup,
        BFormInput,
        BCardText,
        BInputGroupPrepend
    },
    props: {
        isBackgroundPrimary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            productSearchQuery: '',
            bannerImage: require('@/assets/images/banner/banner.png'),
            products: [
                { id: 1, title: 'AquaDentist', desc: 'A comprehensive management system for dentists.', img: require('@/assets/images/illustration/sales.svg'), routeName: 'aqua-dentist' },
                { id: 2, title: 'AquaDerm', desc: 'A specialized system for dermatologists.', img: require('@/assets/images/illustration/marketing.svg'), routeName: 'aqua-derm' },
                { id: 3, title: 'AquaCardio', desc: 'A comprehensive management system for cardiologists.', img: require('@/assets/images/illustration/personalization.svg'), routeName: 'aqua-cardio' },
                { id: 4, title: 'AquaOphtalmo', desc: 'A comprehensive management system for Ophtalmologists.', img: require('@/assets/images/illustration/demand.svg'), routeName: 'aqua-ophtalmo' },
            ],
        }
    },
    computed: {
        filteredProducts() {
            const query = this.productSearchQuery.toLowerCase();
            return this.products.filter(({ title, desc }) =>
                title.toLowerCase().includes(query) || desc.toLowerCase().includes(query)
            );
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/section-products.scss';

.min-vh-100 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>